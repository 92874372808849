export const BASE_URL = '';
export const PRODUCTS_URL = '/api/products';
export const ORDERS_URL = '/api/orders';
export const USERS_URL = '/api/users';
export const UPLOAD_URL = '/api/upload';
export const RAZORPAY_URL = '/api/razorpay';
export const OFFERS_URL = '/api/offers';
export const PREBUILTPC_URL = '/api/prebuiltPc';
export const PERFORMANCE_CAL_URL = '/api/calculatePerformance';
export const SOCIAL_MEDIA_URL = '/api/socialMediaDetail';
export const ABOUT_ADMIN_URL = "/api/update-admin";
export const NEWSLETTER_URL = "/api/newsLetter";
export const SHIPROCKET_URL = "/api/shipRocket";